import React from 'react';
import Layout from '../../../../components/Layout';
import Seo from '../../../../components/SEO';
import SectionIntro from '../../../../components/SectionIntro';
import {useStaticQuery, graphql} from 'gatsby';
import {getImage} from 'gatsby-plugin-image';
import BackgroundImage from 'gatsby-background-image';
import {convertToBgImage} from 'gbimage-bridge';
import BackGlossary from '../../../../components/BackGlossary';
import BannerSimple from '../../../../components/BannerSimple';

const GlossaryPageContent = () => {
  return (
    <div className="md:px-5 max-w-[1285px]">
      <div className="mb-8 text-xl font-bold text-left md:text-2xl">
        Understanding Privileged Access Management for Cloud Systems
      </div>

      <p className="mb-12 text-lg text-left">
        Privileged access management (PAM) is a critical component of an organization's security strategy, particularly
        for cloud systems. It refers to the process of managing and monitoring the use of privileged accounts, which
        have elevated access rights and permissions that can be exploited by attackers if left unchecked. In this
        subpage, we will explore the history of PAM, its downfalls, and how Trustle's streamlined approach to identity
        and access management can provide better defenses for mission-critical systems.
      </p>

      <p className="mb-6 text-lg font-bold text-left">The History of Privileged Access Management</p>
      <p className="mb-12 text-lg text-left">
        PAM has its roots in traditional on-prem IT systems, where the focus was on controlling access to critical
        infrastructure such as servers and databases. However, with the adoption of cloud-based infrastructure and
        software-as-a-service (SaaS) applications, the number of privileged accounts has exploded, making PAM more
        challenging and complex.
      </p>

      <p className="mb-6 text-lg font-bold text-left">What is Privileged Management?</p>
      <p className="mb-12 text-lg text-left">
        Privileged access management refers to the management and control of privileged accounts and their associated
        access rights. This includes the identification and classification of privileged accounts, the enforcement of
        least privilege principles, and the monitoring of privileged activity to detect and respond to potential
        threats.
      </p>

      <p className="mb-6 text-lg font-bold text-left">The Difference Between IAM and PAM</p>
      <p className="mb-12 text-lg text-left">
        Identity and access management (IAM) refers to the broader set of policies, procedures, and technologies used to
        manage user identities and their access to resources across an organization's entire IT environment. PAM, on the
        other hand, focuses specifically on the management of privileged accounts and their associated access rights.
        While IAM is important for managing user access, PAM is essential for securing mission-critical systems against
        insider and outsider threats.
      </p>

      <p className="mb-6 text-lg font-bold text-left">The principles of PAM include:</p>

      <p className="mb-12 text-lg text-left">
        Principle of least privilege: limiting access rights to only what is necessary to perform job functions<br></br>
        Segregation of duties: separating duties to prevent conflicts of interest and reduce the risk of fraud<br></br>
        Continuous monitoring: detecting and responding to privileged activity in real-time<br></br>
        Privileged session management: controlling access to privileged accounts and recording all activity for auditing
        purposes
      </p>

      <p className="mb-6 text-lg font-bold text-left">Why is PAM Important?</p>
      <p className="mb-12 text-lg text-left">
        PAM is crucial for protecting against data breaches, insider threats, and cyber-attacks that can result in
        significant financial and reputational damage. A robust PAM solution helps organizations ensure that only
        authorized personnel have access to sensitive information and systems, reducing the risk of malicious or
        accidental damage.
      </p>

      <p className="mb-6 text-lg font-bold text-left">Trustle's approach to PAM and access management</p>
      <p className="mb-6 text-lg text-left">
        Trustle offers a streamlined approach to identity and access management that leverages your current workflows,
        and drastically increases defenses for your most mission critical systems. We offer centralized management of
        all user accounts, access rights, and permissions across a range of cloud-based infrastructure and SaaS
        applications.
      </p>

      <p className="mb-6 text-lg text-left">
        Trustle is a context based access control platform for companies who need to better safeguard their most
        important Data &amp; Resources. By ingesting the deepest api connections across each cloud and SaaS system, we
        streamline your access request workflows. Then we leverage our machine learning to provide- deeper end-to-end
        visibility and control to each of your systems.
      </p>

      <p className="mb-6 text-lg text-left">
        This allows your team to begin to make context based decisions providing compliant, automated access for the
        right user, to the right resource, for the right length of time.
      </p>

      <p className="mb-6 text-lg text-left">
        It&rsquo;s a complete, yet customizable platform that offers enhanced cybersecurity defenses and enables
        companies to foster secure, compliant collaboration that maximizes product development, cost savings, and
        ultimately, revenue.
      </p>

      <p className="mb-6 text-lg font-bold text-left">Conclusion</p>
      <p className="mb-6 text-lg text-left">
        In summary, privileged access management is a critical aspect of an organization's security strategy for cloud
        systems. Trustle&rsquo;s streamlined approach to PAM can help enterprises manage their privileged accounts more
        effectively, reduce the risk of cyber-attacks, and improve overall security posture. <br></br>
      </p>
    </div>
  );
};

const PamPrivilegedAccessManagment = () => {
  const dataImages = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: {eq: "bg/rebrand/bg-main-top.png"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], quality: 100)
        }
      }
    }
  `);
  const {placeholderImage} = dataImages;
  const image = getImage(placeholderImage);
  const bgImage = convertToBgImage(image);
  const title = `PAM`;
  const description = `Privileged Access Management`;
  return (
    <Layout seo={<Seo title={`Glossary: ${title} | Trustle`} description={description} />}>
      <BackgroundImage
        Tag="section"
        // Spread bgImage into BackgroundImage:
        {...bgImage}
        preserveStackingContext
        className="bg-cover"
      >
        <section className="">
          <div className="flex flex-col pt-16 mx-5 md:mx-8 lg:px-4 lg:max-w-[1920px] lg:mx-auto ">
            <SectionIntro isPageHeader hero="Glossary" title={title} description={`(${description})`} />
          </div>
        </section>

        <section className="pb-16 ">
          <div className="flex flex-col py-16 mx-5 md:mx-8 lg:px-4 lg:max-w-[1920px] lg:mx-auto">
            <div className=" max-w-[1360px] 3xl:max-w-[1420px] mx-auto text-blue">
              <div className="py-16">
                <BackGlossary />
              </div>
              <GlossaryPageContent />
            </div>
          </div>
          <BannerSimple
            title="Curious how Trustle works?"
            description={'Get an inside look at Trustle. Schedule a demo with us today.'}
          />
        </section>
      </BackgroundImage>
    </Layout>
  );
};

export default PamPrivilegedAccessManagment;
